<template>
  <div>
    <v-row class="d-flex">
      <v-col cols="4">
        <site-group-filter store="premium" />
      </v-col>
      <v-col cols="6">
        <site-filter store="premium" />
      </v-col>
      <v-col cols="2" class="d-flex">
        <v-spacer />
        <apply-filters :disabled="isLoading" outlined color="secondary" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ApplyFilters from "@/components/common/filters/ApplyFilters.vue";
import SiteFilter from "@/components/common/filters/SiteFilter";
import SiteGroupFilter from "@/components/common/filters/SiteGroupFilter";

import useAxios from "@/hooks/useAxios";

export default {
  name: "Filters",
  components: {
    ApplyFilters,
    SiteFilter,
    SiteGroupFilter,
  },
  setup(props, { emit }) {
    const { isLoading } = useAxios();

    // =>  METHODS

    // =>  COMPUTED

    // =>  WATCH

    // =>  HOOKS

    // =>  DATA
    return {
      isLoading,
    };
  },
};
</script>

<style></style>
