<template>
  <div ref="pdfContainer" class="pdfContainer">
    <p class="text-2xl mb-6">
      Optimisation des revenus publicitaires
      <dag-info
        :dags="[
          'ga_get_and_store_site_daily_dag',
          'partner_get_and_store_daily_dag',
          'partner_get_and_store_daily_ifempty_dag',
          'ga_post_avg_session_duration_daily_dag',
        ]"
      />
    </p>
    <v-card class="mb-6 pa-2" outlined>
      <v-row class="ma-3">
        <v-col cols="12">
          <filters></filters>
        </v-col>
      </v-row>
    </v-card>

    <template v-for="(site, index) in page.sites">
      <v-card class="mb-6 pa-2" v-if="site" outlined :key="site.name">
        <v-card-title>{{ site.name }}</v-card-title>
        <v-card-text>
          <v-list>
            <v-list-item>
              <v-list-item-icon class="me-2">
                <v-icon size="22">
                  {{ icons.mdiAlertOutline }}
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  Alerte performance - Manque à gagner / jour sur
                  <span v-if="site.posts.length > 1"
                    >ces {{ site.posts.length }} articles</span
                  ><span v-else>cet article</span> : {{ site.missedCa }} ({{
                    site.missedCaPerWeek
                  }}
                  sur 7 jours)
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon class="me-2">
                <v-icon size="22">
                  {{ icons.mdiCurrencyEur }}
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  RPM moyen sur 30j : {{ site.avg_rpm }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon class="me-2">
                <v-icon size="22">
                  {{ icons.mdiTimerOutline }}
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  Temps moyen / page sur 30j :
                  {{ site.avg_session_duration }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon class="me-2">
                <v-icon size="22">
                  {{ icons.mdiMagnifyPlusOutline }}
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  Détails des articles concernés :
                </v-list-item-title>
                <v-list>
                  <v-list-item
                    v-for="(post_data, index) in site.posts"
                    :key="`postData_${index}`"
                  >
                    <v-list-item-icon class="me-2">
                      <v-icon size="22">
                        {{ icons.mdiFileDocumentOutline }}
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>
                        <strong>{{ post_data.title }}</strong>
                      </v-list-item-title>
                      <v-list>
                        <v-list-item>
                          <v-list-item-icon class="me-2">
                            <v-icon size="22">
                              {{ icons.mdiMonitorCellphone }}
                            </v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ post_data.sessions }} sessions hier
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-icon class="me-2">
                            <v-icon size="22">
                              {{ icons.mdiClockTimeFourOutline }}
                            </v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>
                              Temps moyen : {{ post_data.avg_session_duration }}
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-icon class="me-2">
                            <v-icon size="22">
                              {{ icons.mdiAirplaneTakeoff }}
                            </v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>
                              Gain potentiel : {{ post_data.missedCa }} ({{
                                post_data.missedCaPerWeek
                              }}
                              sur 7 jours)
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>

        <progress-bar :download="download" />
      </v-card>
    </template>

    <dot-menu
      position="page-top-right"
      :isLoading="isLoading"
      :pdfButton="{
        getContainerRef: () => $refs.pdfContainer,
        filename: 'ads_revenues_optimization.pdf',
        disabled: loading,
      }"
      @exportExcel="exportExcel"
      :excelButton="{
        excelData: excelButton.data,
        filename: 'ads_revenues_optimization.xlsx',
        disabled: loading,
      }"
    />
  </div>
</template>

<script>
import {
  computed,
  del,
  onMounted,
  reactive,
  ref,
  set,
  watch,
} from "@vue/composition-api";
import {
  mdiAirplaneTakeoff,
  mdiAlertOutline,
  mdiClockTimeFourOutline,
  mdiCurrencyEur,
  mdiFileDocumentOutline,
  mdiMagnifyPlusOutline,
  mdiMonitorCellphone,
  mdiTimerOutline,
} from "@mdi/js";

import DagInfo from "@/components/common/menus/DagInfo";
import DotMenu from "@/components/common/menus/DotMenu";
import ProgressBar, {
  download_initial_state,
} from "@/components/common/ProgressBar";
import Filters from "@/components/premium/ads-revenues-optimization/filters/Filters";

import useAxios from "@/hooks/useAxios";
import useProxy from "@/hooks/useProxy";
import { formatCurrency, formatNumber, formatTime } from "@/utils/formatting";
import { saveRmraView } from "@/utils/rmr-analytics";

export default {
  name: "AdsRevenuesOptimization",
  components: {
    DagInfo,
    DotMenu,
    Filters,
    ProgressBar,
  },
  setup(props, { emit }) {
    const { route, store } = useProxy();
    const { isLoading, axiosGet } = useAxios();

    const loading = ref(false);

    const download = reactive(download_initial_state());
    const excelButton = reactive({
      data: new Blob([""], { type: "text/plain" }),
    });
    const page = reactive({
      sites: {},
    });

    // =>  METHODS
    const formatResult = (data) => {
      page.sites = {};
      for (const post of data) {
        if (!(post.site_id in page.sites)) {
          // Create site entry
          const site = siteList.value.find(
            (_site) => _site.id === post.site_id
          );
          if (!site) {
            continue;
          }

          page.sites[site.id] = {
            id: site.id,
            name: site.name,
            avg_rpm: formatCurrency(post.site_avg_rpm),
            avg_session_duration: formatTime(post.site_avg_session_duration),
            missedCa: 0,
            missedCaPerWeek: 0,
            posts: [],
          };
        }

        // Push post data
        page.sites[post.site_id].posts.push({
          title: post.title,
          sessions: formatNumber(post.sessions),
          avg_session_duration: formatTime(post.avg_session_duration),
          ca: formatCurrency(post.ca),
          missedCa: formatCurrency(post.potential_gain),
          missedCaPerWeek: formatCurrency(post.potential_gain * 7),
        });

        // Increase site's missedCa
        page.sites[post.site_id].missedCa += post.potential_gain;
        page.sites[post.site_id].missedCaPerWeek += post.potential_gain * 7;
      }

      // Site's data formatCurrency
      for (const site_id of Object.keys(page.sites)) {
        page.sites[site_id].missedCa = formatCurrency(
          page.sites[site_id].missedCa
        );
        page.sites[site_id].missedCaPerWeek = formatCurrency(
          page.sites[site_id].missedCaPerWeek
        );
      }
    };

    const getData = async () => {
      if (applyFilters.value && !loading.value) {
        loading.value = true;

        const { data } = await axiosGet("/programmatic/revenue_optimization/", {
          site_ids: siteIds.value.join(","),
        });

        formatResult(data);

        loading.value = false;
      }

      // Force clicking on button again to re-run that request :
      // (we don't call this dispatch earlier to be sure, as state is async, that all listening components could run their requests)
      store.dispatch("common/updateApplyFilters", false);
    };
    const exportExcel = async () => {
      loading.value = true;
      download.running = true;
      const { data } = await axiosGet(
        "/programmatic/revenue_optimization/export_xlsx",
        {
          site_ids: siteIds.value.join(","),
        },
        {
          responseType: "blob",
          onDownloadProgress: (progressEvent) => {
            download.percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
          },
        }
      );

      excelButton.data = data;
      loading.value = false;
    };

    // =>  COMPUTED
    const applyFilters = computed(
      () => store.getters["common/getApplyFilters"]
    );
    const siteList = computed(() => store.getters["common/getSiteList"]);
    const siteIds = computed(() => store.getters["premium/getSites"]);

    // =>  WATCH
    watch(applyFilters, () => {
      getData();
    });

    watch(
      () => download.percentCompleted,
      (newValue) => {
        if (newValue >= 100) {
          setTimeout(() => {
            const initialState = download_initial_state();
            for (const [key, value] of Object.entries(initialState)) {
              set(download, key, value);
            }
            for (const key of Object.keys(download)) {
              if (!(key in initialState)) {
                del(download, key);
              }
            }
          }, 500);
        }
      }
    );

    // =>  HOOKS
    onMounted(async () => {
      saveRmraView(route);

      await getData();
    });

    // =>  DATA
    return {
      isLoading,
      loading,
      download,
      excelButton,
      exportExcel,
      icons: {
        mdiAirplaneTakeoff,
        mdiAlertOutline,
        mdiClockTimeFourOutline,
        mdiCurrencyEur,
        mdiFileDocumentOutline,
        mdiMagnifyPlusOutline,
        mdiMonitorCellphone,
        mdiTimerOutline,
      },
      page,
    };
  },
};
</script>

<style scoped>
.pdfContainer {
  position: relative;
}
</style>
